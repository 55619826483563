import { parseISO } from "date-fns";
import { ListingStage, ListingStatus, ListingType } from "../../utils/mrr/listingConstants";

//! Listing is the same as Property

//TODO: When these come down, will they use a full ReservationGeneral, or a scaled back reservation?
type PLACEHOLDER_ReservationModel = Record<any, any>; //TODO: Update this (we now have a res'v model).

interface ListingIntegrationI {
  channel_name: string,
  is_active: boolean,
  listing_id: string,
  url?: string
}


// Certificate of Insurance
export enum COIStatus {
  Issued = 'Issued',
  Current = 'Current',
  Pending = 'Pending',
  PendingVerification = 'Pending Verification',
  Requested = 'Requested',
  OutOfCompliance = 'Out of Compliance',
  Expired = 'Expired'
};

export class COIModel {
	constructor(public readonly type: string,
				public readonly status: string,
				public readonly name: string,
				public readonly id: string,
				public readonly created_date: string, // DateTime
				public readonly vendor_name: string,
				public readonly tax_id: string,
				public readonly policy_start: string, // Date
				public readonly policy_id: string,
				public readonly policy_end: string, // Date
				public readonly policy_coverage: string,
				public readonly listing_id: string,
				public readonly date_of_receipt: string, // Date
				public readonly coverage_amount: number) { }

  CheckSubmissionRequired() {
    	return this.status === COIStatus.Pending
    	  || this.status === COIStatus.Requested
        || this.status === COIStatus.OutOfCompliance
        || this.status === COIStatus.Expired;
  }
};


function InstantiateCOIFromJSON(jsonRecord: any) {
  const newModel = new COIModel(
    jsonRecord.type,
    jsonRecord.status,
    jsonRecord.name,
    jsonRecord.id,
    jsonRecord.created_date,
    jsonRecord.vendor_name,
    jsonRecord.tax_id,
    jsonRecord.policy_start,
    jsonRecord.policy_id,
    jsonRecord.policy_end,
    jsonRecord.policy_coverage,
    jsonRecord.listing_id,
    jsonRecord.date_of_receipt,
    jsonRecord.coverage_amount
  );

  return newModel;
};


export const coiRecordType = 'Certificate of Insurance';

export class ListingGeneral {
  public readonly listing_status_mapped: string;
  public readonly sortable_name: string;
  public readonly stage_mapped: string;
  public readonly type_mapped: string;
  public readonly effective_date_ISO: Date;
  public readonly check_out_ISO: Date;
  public readonly management_items: COIModel[];

  constructor(
    public readonly bathrooms: number,
    public readonly bedrooms: number,
    private readonly _check_out: string,
    private readonly _effective_date: string,
    public readonly id: string,
    public readonly lessor_name: string,
    private readonly listing_status: string,
    private readonly _management_items_JSON: any,
    public readonly name: string,
    public readonly name_detailed: string,
    public readonly next_check_in: string,
    public readonly owner_name: string,
    public readonly reservations: PLACEHOLDER_ReservationModel[],
    public readonly resort_name: string,
    private readonly stage: string,
    public readonly turnaround: boolean,
    private readonly type: string,
    public readonly unit_id: string,
    public readonly unit_view: string,
    public readonly owner_email: string,
    public readonly owner_phone: string,
    public readonly minimum_rate: number,
    public readonly longitude: number,
    public readonly latitude: number,
    public readonly integrations?: ListingIntegrationI[]
  ) {
    this.sortable_name = this.name ? this.name.toLowerCase() : '';
    this.stage_mapped = this.stage ? ListingGeneral.MapStageToEnum(this.stage) : '';
    this.listing_status_mapped = ListingGeneral.MapStatusToEnum(this.listing_status);
    this.type_mapped = ListingGeneral.MapTypeToEnum(this.type);
    this.check_out_ISO = parseISO(this._check_out)
    this.effective_date_ISO = parseISO(this._effective_date)

    if (!Array.isArray(this._management_items_JSON)) {
      this.management_items = [];
      return;
    }

    this.management_items = this._management_items_JSON.map(
      json => InstantiateCOIFromJSON(json));
  }

  private static MapStageToEnum(stageIn: string): ListingStage {
    const lowerCased = stageIn.toLowerCase();

    switch (lowerCased) {
      case 'confirmed':
      case 'live': {
        return ListingStage.Active;
      }

      case 'inputted':
      case 'pending':
      case 'takeover':
      case 'dormant': {
        return ListingStage.Inactive;
      }

      default: {
        console.warn('unknown listing stage: ' + lowerCased + ' as "' + stageIn + '"');
        return ListingStage.Internal_Unknown;
      }
    }
  }

  //NOTE: This is public so that the reservation model can see it. However, there is a chance
  //      we'll change that stucture so that res'v extends this model. TBD.
  public static MapStatusToEnum(statusIn: string): ListingStatus {
    if (statusIn === undefined) {
      //TODO: We are currently receiving this for some models; not sure whether it's a bug just yet.
      return ListingStatus.Internal_Unknown;
    }

    const lowerCased = statusIn.toLowerCase();

    switch (lowerCased) {
      case 'clean': {
        return ListingStatus.Clean;
      }

      case 'dirty': {
        return ListingStatus.Dirty;
      }

      case 'occupied': {
        return ListingStatus.Occupied;
      }

      default: {
        // don't warn on known unsupported
        if (lowerCased === 'inspected') {
          // don't warn
        }
        else {
          console.warn('unknown listing status: ' + lowerCased + ' as "' + statusIn + '"');
        }
        return ListingStatus.Internal_Unknown;
      }
    }
  }

  public static MapTypeToEnum(typeIn: string): ListingType {
    if (typeIn === undefined) {
      //TODO: We are currently receiving this for some models; not sure whether it's a bug just yet.
      return ListingType.Internal_Unknown;
    }

    const lowerCased = typeIn.toLowerCase();

    switch (lowerCased) {
      case 'condo': {
        return ListingType.Condo;
      }

      case 'house': {
        return ListingType.House;
      }

      case 'villa': {
        return ListingType.Villa;
      }

      default: {
        console.warn('unknown listing type: ' + lowerCased + ' as "' + typeIn + '"');
        return ListingType.Internal_Unknown;
      }
    }
  }
};

export function InstantiateListingFromJSON(jsonRecord: any) {
  const newModel = new ListingGeneral(
    jsonRecord.bathrooms,
    jsonRecord.bedrooms,
    jsonRecord.check_out,
    jsonRecord.effective_date,
    jsonRecord.id,
    jsonRecord.lessor_name,
    jsonRecord.listing_status,
    jsonRecord.management_items,
    jsonRecord.name,
    jsonRecord.name_detailed,
    jsonRecord.next_check_in,
    jsonRecord.owner_name,
    jsonRecord.reservations,
    jsonRecord.resort_name,
    jsonRecord.stage,
    jsonRecord.turnaround,
    jsonRecord.type,
    jsonRecord.unit_id,
    jsonRecord.unit_view,
    jsonRecord.owner_email,
    jsonRecord.owner_phone,
    jsonRecord.minimum_rate,
    jsonRecord.longitude,
    jsonRecord.latitude,
    jsonRecord.integrations
  );

  return newModel;
};
