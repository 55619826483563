/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Card, CardHeader, Container, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, MenuItem, Stack, Switch, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import Iconify from '../../components/iconify/Iconify';
import MenuPopover from '../../components/menu-popover/MenuPopover';
import CommonLoadingIndicator from '../../components/mrr/CommonLoadingIndicator';
import { useSettingsContext } from '../../components/settings';
import useResponsive from '../../hooks/useResponsive';
import { CalendarReservationContentMode } from '../../models/mrr/calendarEvents';
import { COIModel, coiRecordType } from '../../models/mrr/listingGeneral';
import { useGetRTKQReservationsQuery, useGetRTKQSingleListingQuery } from '../../redux/rtkQuery/apiSlice';
import { PATH_HOME } from '../../routes/paths';
import ListingDetailsCard from '../../sections/@dashboard/listing/ListingDetailsCard';
// import ReservationCalendar from '../../sections/@dashboard/reservation/ReservationCalendar';
import { ChartHeatMapYear } from '../../sections/_examples/extra/chart';
import { ReservationTable } from '../../sections/tabels/reservation/ReservationTable';
import TaskTable from '../../sections/tabels/task/TaskTable';
import fTitle from '../../utils/mrr/formatTitle';
import { escapeButtonLabel } from '../../utils/mrr/uiConstants';
import ListingCOICard from '../../sections/@dashboard/listing/list/Page/ListingCOICard';
import { heatMapDateEnd, heatMapDateStart, useHeatmapDates } from './useHeatmapDates';
import { HeatMapLoading } from './HeatMapLoading';
// ----------------------------------------------------------------------

export default function ListingDetailsPage() {

    const { id: listingId } = useParams();

    const isMainMenuVisible = useResponsive('up', 'lg');

    const [openSupportPopOver, setOpenSupportPopOver] = useState<HTMLElement | null>(null)

    const [startDate, endDate] = useHeatmapDates()

    const { themeMode } = useSettingsContext()

    const {
        data: listing,
        isFetching: isFetchingListing,
        error: listingError,
        isUninitialized,
        isSuccess
    } = useGetRTKQSingleListingQuery({ listingId: listingId })

    // const {
    //     data: reservations,
    //     isFetching: isFetchingReservations,
    //     error: reservationsError
    // } = useGetRTKQReservationsQuery({ listingId: listingId })

    const {
        data: heatMapReservationData,
        error: heatMapReservationsError,
        isFetching: isFetchingHeatMap,
        isSuccess: isSuccessHeatMap,
        isError: isErrorHeatMap
    } = useGetRTKQReservationsQuery({ listingId: listingId, startDate: heatMapDateStart, endDate: heatMapDateEnd })

    const integrations = useMemo(() => {
        if (isSuccess && listing.integrations) {
            return listing.integrations
        }
        return []
    }, [listing, isSuccess])


    const handleOpenSupportPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenSupportPopOver(event.currentTarget);
    };
    const handleCloseSupportPopOver = () => {
        setOpenSupportPopOver(null)
    }


    let listingCOIModels: null | COIModel[] = null;

    if (isSuccess && listing) {
        // console.warn('TRACE')
        if (listing.management_items.length > 0) {
            listingCOIModels = listing.management_items.filter(mgmtItem => {
                if (mgmtItem.type !== coiRecordType) {
                    return false;
                }

                return true;
            });
        }
    }


    return (
        <>
            <Helmet>
                <title>{fTitle(`Property${listing?.name_detailed ? ': ' + listing.name_detailed : ''}`)}</title>
            </Helmet>

            {isSuccess &&
                <MenuPopover
                    open={openSupportPopOver}
                    onClose={handleCloseSupportPopOver}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    // transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                    arrow="top-left"
                    sx={{ width: 'auto' }}
                >
                    <MenuItem
                        component='a'
                        href={`mailto:${listing?.owner_email}?subject=${listing?.unit_id} Support`}
                        onClick={() => {
                            handleCloseSupportPopOver();
                        }}
                        sx={{ color: 'primary.main' }}
                    >
                        <Iconify icon="eva:email-outline" />
                        {listing?.owner_email}
                    </MenuItem>
                    <MenuItem
                        component='a'
                        href={`tel:${listing?.owner_phone}`}
                        onClick={() => {
                            handleCloseSupportPopOver();
                        }}
                        sx={{ color: 'primary.main' }}
                    >
                        <Iconify icon="eva:phone-outline" />
                        {listing?.owner_phone}
                    </MenuItem>
                </MenuPopover>
            }

            <Container maxWidth='lg'>
                <CustomBreadcrumbs
                    heading="Property Details"
                    links={[
                        { name: 'Home', href: PATH_HOME.root },
                        {
                            name: 'Properties',
                            href: PATH_HOME.property.root,
                        },
                        { name: isFetchingListing ? 'Loading...' : listing?.name_detailed || '' },
                    ]}
                    action={listingId ?
                        <Button
                            component={RouterLink}
                            to={PATH_HOME.booking.createFromListing(listingId)}
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                            Book This Property
                        </Button> :
                        undefined
                    }
                />
                <Stack spacing={4}>
                    <Grid container spacing={3}>
                        <Grid item sm={8} xs={12}>
                            <Stack spacing={3}>
                                {listingId && <ListingDetailsCard listingId={listingId} handleOpenSupportPopover={handleOpenSupportPopover} />}
                                {listingCOIModels !== null &&
                                    <Stack
                                        direction='column'
                                    >
                                        {listingCOIModels.map((coi, i) => {
                                            return (
                                                <div key={`coi${i}`}>
                                                    <ListingCOICard
                                                        listingId={listingId ? listingId : 'n/a'}
                                                        model={coi}
                                                        modelLoading={isFetchingListing}
                                                    />
                                                </div>);
                                        })}
                                    </Stack >
                                }
                            </Stack>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Stack>
                                {integrations.length > 0 &&
                                    <Card sx={{ p: 2 }}>
                                        <CardHeader
                                            sx={{ padding: 0 }}
                                            title='Listing Channels'
                                        />
                                        <List>
                                            {integrations.map((integration, i) => {
                                                return (
                                                    <ListItemButton disableGutters key={`integration_${i}`} onClick={() => {
                                                        if (integration.is_active && integration.url) {
                                                            window.open(integration.url, '_blank')
                                                        }
                                                    }}>
                                                        <ListItemIcon>
                                                            <img
                                                                style={{ ...(themeMode === 'dark' && { filter: 'brightness(0) invert(1)' }) }}
                                                                height={32}
                                                                width='auto'
                                                                alt={integration.channel_name}
                                                                src={`/assets/icons/brands/ic_brand_${integration.channel_name.toLowerCase()}.svg`}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemSecondaryAction>
                                                            <Switch
                                                                disableRipple
                                                                disableFocusRipple
                                                                disableTouchRipple
                                                                disabled
                                                                edge="end"
                                                                onChange={() => { }}
                                                                checked={integration.is_active}
                                                                readOnly
                                                                inputProps={{
                                                                    'aria-labelledby': 'switch-list-label-wifi',
                                                                }}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItemButton>
                                                )
                                            })}
                                        </List>
                                    </Card>
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                    {isFetchingHeatMap ?
                        <Card>
                            <CardHeader title='Booking Heatmap' />
                            <HeatMapLoading />
                        </Card>
                        :
                        isSuccessHeatMap && heatMapReservationData ?
                            <ChartHeatMapYear reservations={heatMapReservationData} startDate={startDate} endDate={endDate} />
                            : null
                    }
                    <Card>
                        <CardHeader title='Confirmed Reservations' />
                        <ReservationTable listingId={listingId} mode={CalendarReservationContentMode.SingleListing} />
                    </Card>
                    <Card>
                        <TaskTable listingId={listingId} />
                    </Card>
                    {/* <ReservationCalendar listingId={listingId} /> */}
                </Stack >

                {/* Show an 'escape' button while the main menu is hidden */}
                {
                    !isMainMenuVisible &&
                    <>
                        <br />
                        <Button component={RouterLink} to="/" variant="text">
                            {escapeButtonLabel}
                        </Button>
                    </>
                }
            </Container >
        </>
    );
}

//TODO: JULY 31
/*
? Use desktop breakpoints top stack components size to determain the control height of the intergrations card
* If the same could be done at lower breakpoints (before it goes to one stack), that would be great, but the spacing and padding might get too ugly.

! Currently, this card has three modes (submission required, default and pending) with different heights.
* Again, it would be great to accommodate all of them, but that might lead to bad spacing.
 */